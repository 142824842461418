import { Identity } from '@schibsted/account-sdk-browser';
import braze from './braze';
import config from '../config';

const {
  schibstedAccount: { clientId, redirectUri, env, sessionDomain }
} = config;

const identity = new Identity({
  clientId,
  redirectUri,
  env,
  log: console.log, // eslint-disable-line no-console
  sessionDomain
});
window.Identity = identity;

const getRandomChar = () => Math.floor(Math.random() * (122 - 97) + 97);

/**
 * Mostly copied from account-sdk sample app: https://github.com/schibsted/sdk-example
 * @private
 * @return {string}
 */
function getState(redirectUrl) {
  const stateObj = {
    ...(redirectUrl ? { redirectUrl: redirectUrl.toString() } : {}),
    id: Array.from({ length: 20 }, () =>
      String.fromCharCode(getRandomChar())
    ).join('')
  };

  return btoa(JSON.stringify(stateObj));
}

/**
 * Run hasSession() to check if user is logged in to the account, perform a login if he isn't
 * This function will be used by ship-web-components in case of fetch-errors, that's why it performs a full page reload
 * to cleanly recover from an error
 */
const authenticate = originalUrl =>
  identity
    .hasSession()
    .then(() => {
      // User is logged in, redirect to original location, this should hapen rarely
      window.location = originalUrl;
    })
    .catch(() => {
      // if user is not logged to schibsted account then perform a login
      identity.login({ state: getState() });
    });

const loginUser = redirectUrl =>
  identity.login({ state: getState(redirectUrl) });

const logoutUser = redirectUrl => {
  braze.changeUser(null);
  identity.logout(redirectUrl || redirectUri);
};

const changeUser = () => logoutUser(identity.loginUrl({ state: getState() }));

const getUser = () => identity.getUser();

const { isLoggedIn } = identity;

export { loginUser, logoutUser, changeUser, authenticate, getUser, isLoggedIn };
