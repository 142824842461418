"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-underscore-dangle */
const react_1 = require("react");
const sourcepoint_1 = require("@schibsted/sourcepoint");
const usePrivacySettingsIndicator = (config, spidId, tracker) => {
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (spidId !== undefined) {
            const psiConfig = {
                ...config,
                userId: spidId,
                pulseTracker: tracker
            };
            (0, sourcepoint_1.psi)(window, document, navigator, psiConfig);
            setIsReady(true);
        }
        return () => { };
    }, [spidId]);
    return isReady;
};
exports.default = usePrivacySettingsIndicator;
